import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { toKebabCase } from '../helpers'

import style from '../styles/card.module.css'

const Card = ({
  title,
  date,
  path,
  coverImage,
  excerpt,
  tags,
}) => {
  
  return (
    <article className={style.card}>
      <div className={style.postContent}>
        {coverImage && (
          <Link to={path}>
            <Img
              fluid={coverImage.childImageSharp.fluid}
              className={style.coverImage}
            />
          </Link>
        )}
        <div className={style.meta}>
          <div className={style.info}>
            {tags ? (
                <div className={style.tags}>
                  {tags.map(tag => (
                    <Link to={`/tag/${toKebabCase(tag)}/`} key={toKebabCase(tag)}>
                      <span className={style.tag}>#{tag}</span>
                    </Link>
                  ))}
                </div>
              ) : null}
            <Link to={path}>
              <h2 className={style.title}>
                {title}
              </h2>
              <div className={style.excerpt}>
                <p>{excerpt}</p>
              </div>
            </Link>
          </div>
          <div className={style.date}>
            <Link to={path}>
              {date !== "Invalid date" ? date : ""}
            </Link>
          </div>
        </div>
      </div>
    </article>
  )
}

Card.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  path: PropTypes.string,
  coverImage: PropTypes.object,
  excerpt: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
}

export default Card
